import { getKcContext } from 'keycloakify/login'

export const { kcContext } = getKcContext({
  mockPageId: 'login.ftl', // change to preview different pages
  mockData: [
    // mock data scenarios => https://github.com/keycloakify/keycloakify/blob/main/src/login/kcContext/KcContext.ts
    {
      pageId: 'login.ftl', // should match mockPageId
      /* message: {
        type: 'success',
        summary: 'Test',
      }, */
      realm: {
        loginWithEmailAllowed: false,
        internationalizationEnabled: false,
        displayNameHtml: 'Daytona',
        registrationAllowed: false,
        password: true,
        resetPasswordAllowed: false,
        registrationEmailAsUsername: false,
        rememberMe: true,
      },
      social: {
        providers: [
          {
            alias: 'google',
            displayName: 'Google',
          },
          {
            alias: 'github',
            displayName: 'GitHub',
          },
          {
            alias: 'github-enterprise-server',
            displayName: 'GitHub Enterprise Server',
          },
          {
            alias: 'gitlab',
            displayName: 'GitLab',
          },
          {
            alias: 'gitlab-self-managed',
            displayName: 'GitLab Self-Managed',
          },
          {
            alias: 'bitbucket',
            displayName: 'Bitbucket',
          },
          {
            alias: 'bitbucket-server',
            displayName: 'Bitbucket Server',
          },
        ],
      },
    },
  ],
})

export type KcContext = NonNullable<typeof kcContext>
